import React, { useRef, useState, useEffect } from 'react'
import { useIsIntersecting } from 'utils/hooks/useIsIntersecting'
import { useWindowSize } from 'utils/hooks'
import styles from './IconButtonsCard.module.scss'
import cx from 'classnames'
import { IconButtonsCardProps } from '~types'
import Image from 'next/image'
import { Heading } from '~elements'
import { useRouter } from 'next/router'
import { constructS3Url } from 'utils'

const SVGIcon = ({ id, url, width, height }: any) => (
  <svg width={width} height={height} className={cx(styles['icon'], styles['delay-short'])}>
    <defs>
      <linearGradient id={`defaultGradient-${id}`} x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" stopColor="rgba(4,156,177,1)" stopOpacity="1" />
        <stop offset="100%" stopColor="rgba(218, 251, 255,1)" stopOpacity="1" />
      </linearGradient>

      <filter id={`dropShadow-${id}`} x="-20%" y="-20%" width="140%" height="140%">
        <feDropShadow dx="0" dy="0" stdDeviation="8" floodColor="rgba(0, 0, 0, 1)" floodOpacity="0.5" />
      </filter>

      <mask id={`iconMask-${id}`}>
        <image width={width} height={height} href={url} />
      </mask>
    </defs>

    <rect width={width} height={height} fill={`url(#defaultGradient-${id})`} filter={`url(#dropShadow-${id})`} mask={`url(#iconMask-${id})`} />
  </svg>
)

const IconButtonsCard = ({ eyebrow, headline, backgroundImage, backgroundImageUrlS3, backgroundVideo, iconButtons }: IconButtonsCardProps) => {
  const { isBreakpoint: isBelowBreakpointLg } = useWindowSize('lg', '<')
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')

  const hoverRef = useRef(null)
  const [hasAnimationPlayed, setHasAnimationPlayed] = useState(false)
  const isElementIntersecting = useIsIntersecting({
    ref: hoverRef,
    observerOptions: {
      threshold: [0.2]
    }
  })

  useEffect(() => {
    if (isElementIntersecting && !hasAnimationPlayed) {
      setHasAnimationPlayed(true)
    }
  }, [isElementIntersecting, hasAnimationPlayed])
  const enabledButtonsCount = iconButtons.filter(btn => btn.enable).length

  const getDelayClass = (index: number) => {
    if (enabledButtonsCount >= 7 && index >= 4) return styles['delay-long']
    if (enabledButtonsCount <= 6 && index >= 3) return styles['delay-long']
    if (enabledButtonsCount === 4) return styles['delay-long']

    return styles['delay-short']
  }

  const getLayoutClass = (numButtons: number) => {
    if (isBelowBreakpointLg) {
      return styles['two-per-row']
    }
    if (numButtons >= 7 || numButtons === 4) {
      return styles['four-per-row']
    }
    return styles['three-per-row']
  }
  const layoutClass = getLayoutClass(enabledButtonsCount)

  return (
    <div className={styles['icon-buttons-card']} ref={hoverRef}>
      {backgroundVideo?.url ? (
        <video className={styles['background-video']} autoPlay={true} playsInline={true} muted loop controls={false}>
          <source src={backgroundVideo?.url} type="video/mp4" />
          <Image
            width="80"
            height="80"
            alt="video"
            src={constructS3Url(backgroundImageUrlS3)}
            title="Your browser does not support the <video> tag"
          />
        </video>
      ) : (
        <Image src={constructS3Url(backgroundImageUrlS3)} alt={headline || 'Background Image'} fill style={{ objectFit: 'cover' }} />
      )}
      <div className={styles['content-wrapper']}>
        <div className={cx(styles['eyebrow'], hasAnimationPlayed ? styles['animate'] : '')}>
          <Heading level="eyebrow" color="turquoise">
            <span className={styles['span']}>{eyebrow}</span>
          </Heading>
        </div>
        <div className={cx(styles['headline'], hasAnimationPlayed ? cx(styles['animate'], styles['delay-short']) : '')}>
          <Heading level="1">
            <span className={styles['span']}>{headline}</span>
          </Heading>
        </div>
        <div className={styles['cards-container']}>
          {iconButtons.map(
            (btn, index) =>
              btn.enable && (
                <div key={btn.id} className={cx(styles['icon-button'], layoutClass, hasAnimationPlayed ? styles['animate'] : '')}>
                  <a href={btn.url} rel="noreferrer" target={btn.openLinkInNewTab ? '_blank' : '_self'}>
                    <div className={cx(styles['hover-container'], hasAnimationPlayed ? styles['animate'] : '', getDelayClass(index))}>
                      <SVGIcon
                        key={btn.id}
                        id={btn.id}
                        url={constructS3Url(btn.imageUrlS3)}
                        width={isBelowBreakpointMd ? '40' : '80'}
                        height={isBelowBreakpointMd ? '40' : '80'}
                      />
                      <span className={cx(styles['text'], hasAnimationPlayed ? styles['animate'] : '', styles['delay-long'])}>{btn.cta}</span>
                    </div>
                  </a>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  )
}

export default IconButtonsCard
