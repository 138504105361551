import React, { useEffect, useState, useCallback, useId } from 'react'
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { Button, Heading, Paragraph } from '~elements'
import { disableBodyScroll } from 'utils'
import { gaBuyTicketsClick } from 'utils/hooks/gaBtnClick'
import { useRouter } from 'next/router'
import styles from './AnnouncementModal.module.scss'
import { ModalProps, ButtonProps } from '~types'
import truncate from 'truncate-html'

interface AnnouncementModalProps extends ModalProps {
  modalBackground?: { url: string }
  description?: { html: string }
  disclaimer?: string
  statusBar?: string
  statusBarColor?: string
  title?: string
  buttonGroup?: Array<ButtonProps>
  businessUnit?: string
}

const MAX_DESCRIPTION_LENGTH = 160
const MAX_BUTTONS = 2

const AnnouncementModal = ({
  isOpen,
  handleModalClose,
  closeOnOutsideClick = true,
  modalBackground,
  description,
  disclaimer,
  statusBar,
  statusBarColor,
  title,
  buttonGroup,
  businessUnit
}: AnnouncementModalProps) => {
  const [isBrowser, setIsBrowser] = useState(false)
  const { query } = useRouter()
  const [source] = query.slug ? [...(query.slug as string[])].reverse() : ['/']

  const uniqueId = useId()
  const titleId = title ? `modal-title-${uniqueId}` : undefined
  const descriptionId = description?.html ? `modal-description-${uniqueId}` : undefined
  const rawHtml = description?.html || ''
  const truncatedHtml = truncate(rawHtml, { length: MAX_DESCRIPTION_LENGTH, keepImageTag: true, reserveLastWord: true, ellipsis: '' })

  useEffect(() => {
    setIsBrowser(true)
    return () => disableBodyScroll(false)
  }, [])

  useEffect(() => {
    disableBodyScroll(isOpen)
  }, [isOpen])

  const handleKeyEvent = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen && handleModalClose) {
        handleModalClose()
      }
    },
    [isOpen, handleModalClose]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyEvent)
    return () => window.removeEventListener('keydown', handleKeyEvent)
  }, [handleKeyEvent])

  const onModalClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target === e.currentTarget && closeOnOutsideClick && handleModalClose) {
      handleModalClose()
    }
  }

  const limitedButtonGroup = buttonGroup?.slice(0, MAX_BUTTONS)

  const handleButtonClick = (buttonProps: ButtonProps) => {
    if (buttonProps.title.toLowerCase() === 'buy tickets') {
      const snakeCaseTitle = title
        ? title
            .split(' ')
            .map(x => x.toLocaleLowerCase())
            .join('_')
        : ''

      gaBuyTicketsClick({
        businessUnit,
        buyType: buttonProps.buyType,
        clickSource: source ?? '',
        clickTracking: `${window.location.href} ${snakeCaseTitle}:buy_tickets`,
        eventDate: '',
        eventVenueName: 'sphere_vegas',
        eventCategory: 'other',
        eventType: 'other events',
        eventDataStatus: '',
        linkLocation: AnnouncementModal.displayName,
        linkPosition: '',
        ticketmasterEventId: '',
        cmsIdentifier: buttonProps.cmsIdentifier,
        buttonPlacementDescription: buttonProps.buttonPlacementDescription
      })
    }
  }

  const modalContent = isOpen ? (
    <div
      className={styles.modal}
      role="dialog"
      aria-modal="true"
      {...(titleId && { 'aria-labelledby': titleId })}
      {...(descriptionId && { 'aria-describedby': descriptionId })}
    >
      <div className={styles['modal-dialog']} role="document" onClick={onModalClick}>
        <div className={styles['modal-content']} style={{ backgroundImage: `url(${modalBackground})` }}>
          <FontAwesomeIcon icon={faXmark} onClick={handleModalClose} focusable />
          {statusBar && (
            <div className={styles['statusbar-container']}>
              <div className={`${styles.statusbar} ${statusBarColor ? styles[`statusbar-color-${statusBarColor}`] : ''}`}>
                <p>{statusBar}</p>
              </div>
            </div>
          )}
          {title && (
            <Heading level="2" justify="center" id={titleId}>
              {title}
            </Heading>
          )}

          {description?.html && (
            <div className={styles['description-container']} id={descriptionId}>
              <Paragraph text={{ html: truncatedHtml }} />
            </div>
          )}

          {limitedButtonGroup && limitedButtonGroup.length > 0 && (
            <div className={styles['btn-container']}>
              {limitedButtonGroup.map((buttonProps, index) => {
                if (buttonProps.isEnabled) {
                  return <Button key={`${buttonProps.title}_${index}`} {...buttonProps} onClick={() => handleButtonClick(buttonProps)} />
                }
              })}
            </div>
          )}

          {disclaimer && (
            <div className={styles['disclaimer-container']}>
              <p>{disclaimer}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null

  if (isBrowser) {
    let modalRoot = document.getElementById('root-modal')
    if (!modalRoot) {
      modalRoot = document.createElement('div')
      modalRoot.id = 'root-modal'
      document.body.appendChild(modalRoot)
    }
    return ReactDOM.createPortal(modalContent, modalRoot)
  } else {
    return null
  }
}

export default AnnouncementModal

AnnouncementModal.displayName = 'announcement_modal'
