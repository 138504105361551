import React from 'react'
import styles from './404.module.scss'
import { ButtonProps, DoesNotExistProps } from '~types'
import { constructS3Url } from 'utils'
import { Button } from '~elements'
import { useWindowSize } from 'utils/hooks'
import { gaBtnClick, gaBuyTicketsClick } from 'utils/hooks/gaBtnClick'
import { CTA_CLICK_EVENT } from '~analyticsConstants'
import { HOME_URL } from '~constants'

const DoesNotExist = ({ router, backgroundImageS3Url, buttonGroup, businessUnit }: DoesNotExistProps) => {
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')
  const { query } = router
  const [source] = query.slug ? [...(query.slug as string[])].reverse() : ['/']

  const handleBackHomeButtonClick = (title = 'Back Home') => {
    gaBtnClick(CTA_CLICK_EVENT, DoesNotExist.displayName, title)
    router.push(HOME_URL)
  }

  const handleButtonClick = (buttonProps: ButtonProps) => {
    if (buttonProps.title.toLowerCase() === 'buy tickets' && !buttonProps.displayFevoWidget) {
      gaBuyTicketsClick({
        businessUnit,
        buyType: buttonProps.buyType,
        clickSource: source ?? '',
        clickTracking: `${window.location.href} does_not_exists:buy_tickets`,
        eventDate: '',
        eventVenueName: 'sphere_vegas',
        eventCategory: 'other',
        eventType: 'other events',
        eventDataStatus: '',
        linkLocation: DoesNotExist.displayName,
        linkPosition: '',
        ticketmasterEventId: '',
        cmsIdentifier: buttonProps.cmsIdentifier,
        buttonPlacementDescription: buttonProps.buttonPlacementDescription
      })
    } else {
      gaBtnClick(CTA_CLICK_EVENT, DoesNotExist.displayName, buttonProps.title)
    }
  }

  return (
    <div className={styles['not-found']} style={{ backgroundImage: `url(${constructS3Url(backgroundImageS3Url)})` }}>
      <div className={styles['overlay']}></div>
      <div className={styles['does-not-exist-inner-container']}>
        <div></div>404
      </div>

      <div className={styles['does-not-exist-inner-content-container']}>
        <div className={styles['heading-text']}>
          <div className={styles['text-main']}>Page Not Found</div>
          <div className={styles['text-lead']}>
            <span>Oops. We couldn’t find that page.</span>
            <span>Maybe you can find what you need here?</span>
          </div>
        </div>
        <div className={styles['back-home-btn-container']}>
          <Button
            isFullWidth={isBelowBreakpointMd}
            title="Back Home"
            key={'cm5pfujvn02j90dkcxqslln9e'}
            fill="solid"
            bgColor="white"
            color="light"
            onClick={() => handleBackHomeButtonClick()}
          />
        </div>
        <div className={styles['text-bottom']}>
          <span>or go to</span>
        </div>
        {buttonGroup && buttonGroup.length > 0 && (
          <div className={styles['bottom-btn-container']}>
            {buttonGroup.map((buttonProps, index) => {
              return <Button key={`${buttonProps.title}_${index}`} {...buttonProps} width="100%" onClick={() => handleButtonClick(buttonProps)} />
            })}
          </div>
        )}
      </div>
    </div>
  )
}

DoesNotExist.displayName = 'does_not_exist'

export default DoesNotExist
