import React, { Suspense, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { PreviewAlert } from '../../elements'
import { fireAnalytics, getPageType } from '~analyticsUtils'
import styles from './Page.module.scss'
import { ANALYTICS_EVENT_PAGE_LOAD } from '~analyticsConstants'
import AnnouncementModal from '../AnnouncementModal/AnnouncementModal'
import { TWENTY_FOUR_HOURS_IN_MS } from '~constants'

interface BlokInterface {
  body: Array<object>
}

interface PageProps {
  blok: BlokInterface
  components: any
  preview: boolean
  constantsMap: object
  lostandfoundData: string
}

const Page = ({ blok, components, preview, lostandfoundData }: PageProps) => {
  const { body = {} } = blok
  const { blocks = [], slug = '', constantsMap } = body
  const { asPath } = useRouter()

  const [interactive, setInteractive] = useState(false)
  const [isStickyDisclaimerOpen, setIsStickyDisclaimerOpen] = useState(true)

  //lines 32 - 69 and 87-99 are relevant if content editors add an AnnouncementModal in Hygraph to any page
  const announcementModalBlock = blocks.find((block: any) => block.componentName === 'AnnouncementModal')
  const [isAnnouncementOpen, setIsAnnouncementOpen] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined' && announcementModalBlock) {
      const modalClosedAt = localStorage.getItem('modalClosedAt')
      const now = new Date().getTime()

      if (modalClosedAt) {
        const elapsed = now - parseInt(modalClosedAt, 10)

        if (elapsed < TWENTY_FOUR_HOURS_IN_MS) {
          setIsAnnouncementOpen(false)
          localStorage.setItem('isModalOpen', 'false')
          return
        } else {
          localStorage.removeItem('modalClosedAt')
        }
      }

      const storedIsModalOpen = localStorage.getItem('isModalOpen')
      if (storedIsModalOpen === 'true') {
        setIsAnnouncementOpen(true)
      } else {
        const timer = setTimeout(() => {
          setIsAnnouncementOpen(true)
        }, announcementModalBlock.modalTimeout * 1000)

        return () => clearTimeout(timer)
      }
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined' && announcementModalBlock) {
      localStorage.setItem('isModalOpen', isAnnouncementOpen.toString())
    }
  }, [isAnnouncementOpen])

  useEffect(() => {
    fireAnalytics(ANALYTICS_EVENT_PAGE_LOAD, getPageType(asPath))
  }, [slug])

  const handleInteractiveOverlayCardLoad = value => {
    setInteractive(value)
  }
  const handleStickyDisclaimerCloseClick = value => {
    setIsStickyDisclaimerOpen(!value)
  }

  const componentsList = blocks.map((block, index) => {
    const { componentName } = block
    const DynamicComponent = components[componentName]
    const componentProps = {
      ...block
    }

    if (DynamicComponent) {
      return (
        <DynamicComponent
          key={index}
          {...componentProps}
          constantsMap={constantsMap}
          lostandfoundData={lostandfoundData}
          handleInteractiveOverlayCardLoad={value => handleInteractiveOverlayCardLoad(value)}
          handleStickyDisclaimerCloseClick={value => handleStickyDisclaimerCloseClick(value)}
          interactive={interactive}
          isStickyDisclaimerOpen={isStickyDisclaimerOpen}
          businessUnit={body.businessUnit ? body.businessUnit : ''}
        />
      )
    }
  })

  return (
    <main>
      <Suspense fallback={<div>Loading...</div>}>{componentsList}</Suspense>
      {preview && (
        <div className={styles.previewAlertContainer}>
          <PreviewAlert />
        </div>
      )}
      {announcementModalBlock && (
        <AnnouncementModal
          isOpen={isAnnouncementOpen}
          handleModalClose={() => setIsAnnouncementOpen(false)}
          title={announcementModalBlock.title}
          description={{ html: announcementModalBlock.description.html }}
          disclaimer={announcementModalBlock.disclaimer}
          statusBar={announcementModalBlock.statusBar}
          statusBarColor={announcementModalBlock.statusBarColor}
          modalBackground={announcementModalBlock.modalBackground.url}
          buttonGroup={announcementModalBlock.buttonGroup}
        />
      )}
    </main>
  )
}

export default Page
