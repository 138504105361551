import { ReactNode } from 'react'
import moment from 'moment-timezone'
import cx from 'classnames'
import Link from 'next/link'
import Image from 'next/image'
import { getCustomBreakpoint } from 'utils/hooks'

import { DATE_FORMAT, DAYS_OF_THE_WEEK } from '~constants'
import { Heading } from '~elements'
import { EventDayProps, MonthViewProps } from 'types/calendar'
import { getValidShows } from 'utils/calendar'

import styles from '../../../ShowsCalendar.module.scss'
import { constructS3Url } from 'utils'

const MonthViewEventMonth = ({ calendarList, handleDateSelection, month, selectedDate, artistDetails }: MonthViewProps) => {
  const currentDate = moment()
  const monthStart = moment(month).clone().startOf('month')
  const monthEnd = moment(month).clone().endOf('month')
  let startDate = monthStart.clone().startOf('week')
  let endDate

  // Determine if the current date is the last Sunday of the month
  const isLastSunday = currentDate.isSame(monthEnd.clone().day(-1), 'day')
  const endOfMonthDay = monthEnd.day()
  const isLastWeekOfMonth = currentDate.isSame(monthEnd, 'week')

  const monthEndDay = monthEnd.day()
  // Sunday, Monday, Tuesday
  if (monthEndDay === 0 || monthEndDay === 1 || monthEndDay === 2) {
    endDate = monthEnd.clone().endOf('week')
  } else {
    // Wednesday, Thursday, Friday, Saturday
    endDate = monthEnd.clone().endOf('week').add(1, 'week').endOf('week')
  }
  console.log('monthEndDay', monthEndDay)

  const dateFormat = 'D'
  const weeks = []
  const { isBreakpoint: isAboveBreakpointLg } = getCustomBreakpoint(1440, '>=')

  let week: Array<ReactNode> = []
  let day = startDate
  let formattedDate = ''

  const getMaxShowtimes = () => {
    if (isAboveBreakpointLg) {
      return 2
    }
    return 1
  }

  const maxShowtimes = getMaxShowtimes()

  while (day <= endDate) {
    DAYS_OF_THE_WEEK.map((weekday: number) => {
      formattedDate = day.format(dateFormat)
      const cloneDay = day.format(DATE_FORMAT.SHORT_DATE_FORMAT)
      const monthLabel = day.format(DATE_FORMAT.MONTH_FULL_NAME).toLowerCase()
      const [filteredDate] = calendarList
        ? calendarList.filter(({ date }) => date === moment.utc(cloneDay, DATE_FORMAT.SHORT_DATE_FORMAT).valueOf())
        : []
      const hadEventsAvailableInMonth = calendarList
        ? calendarList.some(({ date, events }) => {
            return (
              moment(date).format(DATE_FORMAT.SHORT_MONTH).toLowerCase() === day.format(DATE_FORMAT.SHORT_MONTH).toLowerCase() && events.length > 0
            )
          })
        : false

      const { artists, events = [], eventsCount }: EventDayProps = filteredDate ?? { price: { min: 0, max: 0 } }

      const dayIsFromDifferentMonth = day.isBefore(monthStart)
      const isDateValid = !day.isBefore(new Date(), 'day')
      const dayHasEvents = eventsCount > 0
      const dayClasses = cx([
        styles['calendar__day'],
        dayHasEvents && styles['calendar__day--has-events'],
        day.isSame(new Date(), 'day') && styles['calendar__day--is-current'],
        day.isSame(selectedDate, 'day') && styles['calendar__day--is-selected'],
        dayIsFromDifferentMonth && styles['calendar__day--is-from-different-month'],
        !dayHasEvents && styles['calendar__day--has-no-events'],
        !dayHasEvents && isDateValid && styles['calendar__day--has-no-events-active'],
        day.isBefore(new Date(), 'day') && styles['calendar__day--has-passed'],
        !hadEventsAvailableInMonth && styles['calendar__month--has-no-events'],
        !dayHasEvents && styles['calendar__day--is-disabled'],
        true && [`${monthLabel}-${formattedDate}`]
      ])
      const isPastDate = day.isSameOrBefore(new Date(), 'day')

      const dateClasses = cx([
        styles['calendar__date-dd'],
        !dayHasEvents && !day.isSame(new Date(), 'day') && styles['calendar__day--has-no-events'],
        !day.isSame(new Date(), 'day') && dayHasEvents && styles['calendar__day--upcoming-event'],
        day.isSame(new Date(), 'day') && styles['calendar__day--active'],
        day.isBefore(new Date(), 'day') && styles['calendar__day--has-passed'],
        isPastDate && !dayHasEvents && !day.isSame(new Date(), 'day') && styles['calendar__day--has-passed'],
        !isPastDate && !dayHasEvents && styles['calendar__day--active']
      ])

      const artistsList = [...new Set(artists?.map(artist => artist?.id))]
      const sortedArtistByEvents = [...new Set(events?.flatMap(({ artists = [] }) => artists?.map(({ id }) => id)))]
      const filteredArtists = sortedArtistByEvents?.filter(eventArtist => artistsList?.some(artist => artist === eventArtist))

      const artistDetailsArray = (filteredArtists ?? [])
        .map(artist => {
          const artistId = artist ?? null
          const artistImageObject = artistId && artistDetails ? artistDetails[artistId] : null

          if (artistImageObject) {
            return {
              artistId: artistId,
              artistName: artistImageObject ? artistImageObject?.artistName : null,
              artistEDPUrl: artistImageObject ? artistImageObject?.edpUrl : null,
              artistImageUrl: artistImageObject ? constructS3Url(artistImageObject?.artistImageURLS3) : null
            }
          }
          return null
        })
        .filter(item => item !== null)

      const artistEvents = (artistId: any) => events?.filter(event => event.artists.some(artist => artist.id === artistId))

      const eventTimes = (artistId: string) => {
        const filteredEvents = artistEvents(artistId)
        return getValidShows(filteredEvents)
      }

      const displayActiveTimes = activeTimes => {
        const slicedTimes = activeTimes.slice(0, maxShowtimes)
        return slicedTimes.map((event, index) => {
          const { id, start_time, is_sold_out } = event
          const formattedStartTime = moment(start_time, DATE_FORMAT.TWELVE_HRS).format(DATE_FORMAT.TWELVE_HRS)

          const btnClasses = cx([styles['calendar__event-time'], is_sold_out && styles['calendar__event--is-disabled']])

          return (
            <span key={id} disabled={is_sold_out} className={btnClasses} title={is_sold_out ? 'Tickets sold out!' : undefined}>
              {formattedStartTime}
              {index < slicedTimes.length - 1 && <>&#44; &nbsp;</>}
            </span>
          )
        })
      }

      const activeEvents = getValidShows(events)

      week.push(
        <div key={`${monthLabel}-${weekday}`} className={dayClasses}>
          {!dayIsFromDifferentMonth && (
            <div className={styles['calendar__day-header']}>
              <div className={dateClasses}>{formattedDate}</div>
            </div>
          )}
          <>
            {artistDetailsArray.map((a, index) => {
              const eventTimesForArtist = eventTimes(a.artistId)
              if (eventTimesForArtist.length > 0) {
                return (
                  <div key={index} className={styles['event-container']} onClick={() => handleDateSelection(eventTimesForArtist)}>
                    <Image
                      alt={a.artistName}
                      src={a.artistImageUrl}
                      loading="lazy"
                      width={0}
                      height={0}
                      sizes="100vw"
                      className={styles['edp-image']}
                    />
                    <span className={styles['event-title']}>{a.artistName}</span>
                    <div className={styles['calendar__events']}>
                      {displayActiveTimes(eventTimesForArtist)}
                      {eventTimesForArtist.length > maxShowtimes && (
                        <span className={cx(styles['more-events'])}>&nbsp; + {eventTimesForArtist.length - maxShowtimes} more</span>
                      )}
                    </div>
                  </div>
                )
              } else {
                return null
              }
            })}
          </>
          {isDateValid && activeEvents.length === 0 && (!dayIsFromDifferentMonth || isLastWeekOfMonth) && (
            <div className={cx([styles['calendar__no-events'], [styles['calendar__no-events_with_margin']]])}>
              <span>No show today</span>
            </div>
          )}
        </div>
      )
      day = day.clone().add(1, 'day')
    })

    weeks.push(week)
    week = []
  }

  return weeks.map((week, index) => {
    return (
      <div key={index} className={styles['calendar__week']}>
        {week}
      </div>
    )
  })
}

export default MonthViewEventMonth
