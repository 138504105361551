// import { IGroupVotingButton } from 'types/voting'
import { VotingButton } from '~elements'
import styles from './CohortVotingButton.module.scss'
import { Container } from 'react-grid-system'

const CohortVotingButton = ({ title, votingButtons }: IGroupVotingButton) => {
  return (
    <Container className={styles['cohort-wrapper']}>
      <div className={styles['cohort-container']}>
        <h2 className={styles['cohort-heading-text']}>{title}</h2>
        <div className={styles['cohort-buttons-container']}>
          {votingButtons && votingButtons.map((vBtn, votingBtnIndex) => <VotingButton key={`voting-button-${votingBtnIndex}`} {...vBtn} />)}
        </div>
      </div>
    </Container>
  )
}

export default CohortVotingButton
