import { useEffect, useRef, useState } from 'react'
import Button from '../Button/Button'
import styles from './ShareButton.module.scss'
import cx from 'classnames'
import { CLICK_CONTENT, ANALYTICS_LINK_LOCATION_BODY } from '~analyticsConstants'
import { gaDLPush } from 'utils/hooks/gaDLPush'

interface IShareButtonProps {
  isFullWidth: boolean
  title: string
  text?: string
  size?: string
  url?: string
  type?: 'result-modal'
  useNavigatorShare: boolean //If false, simply copy link to clipboard
}

function ShareFunction({ isFullWidth, title, size, url, type, useNavigatorShare = true }: IShareButtonProps) {
  const [canOpenShare, setCanOpenShare] = useState(false)

  const timer = useRef<NodeJS.Timeout>()
  const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false)

  const shareData = {
    url: new URL(window.location.href).toString(),
    title: `${title} | XO Student Design Challenge | Sphere`
  }

  useEffect(() => {
    if (navigator.canShare && navigator.canShare(shareData)) {
      setCanOpenShare(true)
    }
  }, [])

  const handleClick = async () => {
    if (timer.current) {
      clearTimeout(timer.current)
      setIsLinkCopied(false)
    }
    //If a url is passed to this component, that is used as the shared url instead of the current location.
    const shareData = {
      url: url ? url : new URL(window.location.href).toString(),
      title: `${title} | XO Student Design Challenge | Sphere`
    }
    gaDLPush({
      event: CLICK_CONTENT,
      click_source: window.location.pathname,
      click_tracking: 'artwork_details:share',
      click_type: 'artwork_details:button',
      link_location: 'body',
      link_module: 'artwork_details:button:share',
      link_type: 'internal'
    })

    if (canOpenShare && useNavigatorShare) {
      try {
        await navigator.share(shareData)
      } catch (error) {
        console.error('Unable to share')
      }
    } else {
      navigator.clipboard.writeText(shareData.url)
      setIsLinkCopied(true)

      timer.current = setTimeout(() => {
        setIsLinkCopied(false)
      }, 3000)
    }
  }

  useEffect(() => {
    // Clear timeout on component unmount
    return () => clearTimeout(timer.current)
  }, [])

  return (
    <div className={cx(styles['share-button'], type === 'result-modal' ? styles['result-share'] : '')} data-can-share={canOpenShare}>
      <Button
        isFullWidth={isFullWidth}
        fill="hollow"
        onClick={() => handleClick()}
        size={size}
        // @ts-ignore
        title={
          <div data-type="btn-text">
            <span className={cx(styles['tooltip-text'], isLinkCopied ? styles['show'] : '')}>Link Copied!</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 15 20" fill="none">
              <path d="M2.50405 19.6539C2.0438 19.6539 1.65951 19.4997 1.35117 19.1914C1.04284 18.8831 0.888672 18.4988 0.888672 18.0385V8.26927C0.888672 7.80902 1.04284 7.42472 1.35117 7.11639C1.65951 6.80806 2.0438 6.65389 2.50405 6.65389H4.61945V7.65389H2.50405C2.35021 7.65389 2.20919 7.71799 2.08097 7.84619C1.95277 7.97441 1.88867 8.11543 1.88867 8.26927V18.0385C1.88867 18.1924 1.95277 18.3334 2.08097 18.4616C2.20919 18.5898 2.35021 18.6539 2.50405 18.6539H13.2733C13.4271 18.6539 13.5682 18.5898 13.6964 18.4616C13.8246 18.3334 13.8887 18.1924 13.8887 18.0385V8.26927C13.8887 8.11543 13.8246 7.97441 13.6964 7.84619C13.5682 7.71799 13.4271 7.65389 13.2733 7.65389H11.1579V6.65389H13.2733C13.7335 6.65389 14.1178 6.80806 14.4262 7.11639C14.7345 7.42472 14.8887 7.80902 14.8887 8.26927V18.0385C14.8887 18.4988 14.7345 18.8831 14.4262 19.1914C14.1178 19.4997 13.7335 19.6539 13.2733 19.6539H2.50405ZM7.38867 13.1539V2.26737L5.28867 4.36734L4.58097 3.65389L7.88867 0.346191L11.1964 3.65389L10.4887 4.36734L8.38867 2.26737V13.1539H7.38867Z" />
            </svg>
            &nbsp; Share
          </div>
        }
      />
    </div>
  )
}

export default ShareFunction
