type StructureDataType = {
  [k: string]: object
}

export type QuestionAnswerSDMainEntityType = {
  '@type': string
  name: string
  acceptedAnswer: {
    '@type': string
    text: string
  }
}

export type QuestionAnswerSDType = {
  '@context': string
  '@type': string
  mainEntity: Array<QuestionAnswerSDMainEntityType>
}

const SPHERE_BASE_LOCATION = {
  location: {
    '@type': 'Place',
    name: 'Sphere',
    address: {
      '@type': 'PostalAddress',
      streetAddress: '255 Sands Ave. Las Vegas',
      addressRegion: 'NV',
      postalCode: '89169'
    }
  }
}

const STRUCTURED_BASE_EVENT = {
  '@context': 'http://schema.org',
  '@type': 'Event'
}

export const EDP_STRUCTURED_DATA: StructureDataType = {
  '/shows/the-sphere-experience': {
    ...STRUCTURED_BASE_EVENT,
    image: '',
    url: 'https://www.thesphere.com/shows/the-sphere-experience',
    name: 'The Sphere Experience',
    description:
      'The Sphere Experience is a two-part program and is one-of-a-kind immersive technology experience created specifically for Sphere in Las Vegas.',
    ...SPHERE_BASE_LOCATION
  },
  '/shows/v-u2-immersive-concert-film': {
    ...STRUCTURED_BASE_EVENT,
    image: '',
    url: 'https://www.thesphere.com/shows/v-u2-immersive-concert-film',
    name: 'V-U2 An Immersive Concert File',
    description: 'V-U2 An Immersive Concert Film is a one-of-a-kind cinematic experience shown exclusively at Sphere.',
    ...SPHERE_BASE_LOCATION
  },
  '/shows/eagles': {
    ...STRUCTURED_BASE_EVENT,
    image: '',
    url: 'https://www.thesphere.com/shows/eagles',
    name: 'Eagles - Live in Concert at Sphere',
    ...SPHERE_BASE_LOCATION,
    description:
      'The Eagles residency will offer fans the ultimate connection to the band’s legendary catalog in an immersive experience that only Sphere can provide.',
    performer: {
      '@type': 'Person',
      name: 'Eagles'
    }
  },
  '/shows/dead-and-company': {
    ...STRUCTURED_BASE_EVENT,
    image: '',
    url: 'https://www.thesphere.com/shows/dead-and-company',
    name: 'Dead & Company: Dead Forever',
    ...SPHERE_BASE_LOCATION,
    description:
      'Following their highly successful 30-show "Dead Forever–Live At Sphere" residency, Dead & Company are set to return to Sphere celebrating the band\'s 10-year anniversary.',
    performer: {
      '@type': 'Person',
      name: 'Dead & Company'
    }
  },
  '/shows/afterlife-presents-anyma': {
    ...STRUCTURED_BASE_EVENT,
    image: '',
    url: 'https://www.thesphere.com/shows/afterlife-presents-anyma',
    name: "Anyma 'The End Of Genesys'",
    ...SPHERE_BASE_LOCATION,
    description:
      'Witness a powerful combination of music, art, and technology with Afterlife presents Anyma ‘The End of Genesys,” the first electronic music show at Sphere.',
    ...SPHERE_BASE_LOCATION
  }
}
