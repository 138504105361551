import React, { useState } from 'react'
import cx from 'classnames'
import styles from './Hero.module.scss'
import { Col, Container, Row } from 'react-grid-system'
import { Form, Heading, PortalModal, Paragraph, Vimeo, Breadcrumb } from '~elements'
import Image from 'next/image'
import { objHasValue } from 'utils'
import { ImageProps, VimeoProps } from '~types'
import * as elements from '~elements'
import { useWindowSize } from 'utils/hooks'
import { gaBtnClick } from 'utils/hooks/gaBtnClick'
import { CTA_CLICK_EVENT } from '~analyticsConstants'
import { XO_PATHNAME_TO_DISPLAYNAME } from '~constants'

interface HeroProps {
  backgroundImage?: ImageProps
  ctaSection?: Array<ctaSectionProps>
  description: {
    html: string
  }
  detailSection?: VimeoProps
  eyebrow?: string
  isShort?: boolean
  isSignupPage?: boolean
  displayBreadcrumb?: boolean
  subTitle?: string
  subheadline: string
  title: string
  readMore?: {
    html: string
  }
  isGalleryComponent?: boolean
  logo?: ImageProps
}

interface ctaSectionProps {
  id: string
  buttonText: string
  categories: Array<string>
  component: string
  locations: Array<string>
  urlBase: string
}

const Hero = ({
  backgroundImage,
  ctaSection,
  description,
  detailSection,
  eyebrow,
  isShort = false,
  isSignupPage,
  displayBreadcrumb,
  readMore,
  title,
  subTitle = '',
  subheadline = '',
  isGalleryComponent,
  logo
}: HeroProps) => {
  const hasIcimsCareerForm = ctaSection?.some(block => objHasValue(block, 'componentName', 'CareerForm'))
  const isEdpHero = detailSection && subTitle
  const [readMoreIsOpen, setReadMoreIsOpen] = useState(false)
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')
  const [showSignUpModal, setShowSignupModal] = useState<boolean>(false)
  const [modalFormId, setModalFormId] = useState<string>('')
  const toggleReadMore = () => setReadMoreIsOpen(!readMoreIsOpen)
  const VimeoComponent = () => detailSection && detailSection.url && detailSection.posterImage && <Vimeo {...detailSection} />

  const ctaFormHandler = (id: string, btnText: string) => {
    setShowSignupModal(true)
    setModalFormId(id)
    gaBtnClick(CTA_CLICK_EVENT, title, btnText)
  }

  const formSubmitHandler = () => {
    setShowSignupModal(false)
    setModalFormId('')
  }

  const onCloseModalHandler = () => {
    setShowSignupModal(false)
    setModalFormId('')
  }

  return (
    <>
      <div
        className={cx([
          styles['hero-container'],
          styles[logo ? 'has-logo' : ''],
          styles[hasIcimsCareerForm ? 'has-icims-career-form' : ''],
          styles[isEdpHero && !hasIcimsCareerForm ? 'has-details-and-subhead' : 'has-single-heading'],
          styles[isShort && !isEdpHero ? 'is-short' : '']
        ])}
      >
        {displayBreadcrumb && (
          <div className={styles['breadcrumb-container']}>
            <Breadcrumb pathNameToDisplayName={XO_PATHNAME_TO_DISPLAYNAME} />
          </div>
        )}
        <div className={isSignupPage ? styles['email-signup-page'] : styles['hero']}>
          <Container className={'container container--half-padding-xs'}>
            <Row>
              <Col className={'margin-top-auto'} xl={detailSection && detailSection.url ? 7 : 12} lg={detailSection && detailSection.url ? 6 : 12}>
                {eyebrow && <Heading level={6}>{eyebrow}</Heading>}
                {title && logo ? (
                  <div className={styles['title-with-logo']}>
                    <Heading level="1">{title}</Heading>
                    <img className={styles.logo} src={logo.url} alt={logo.alt || 'Logo'} />
                  </div>
                ) : (
                  title && <Heading level={'display'}>{title}</Heading>
                )}
                {subTitle && <Heading level={2}>{subTitle}</Heading>}
                {subheadline && <p className={styles['subheadline']}>{subheadline}</p>}
                {description && (
                  <div className={styles['description-container']}>
                    <Paragraph text={description} fontSize={isEdpHero ? 'md' : 'lg'} />
                  </div>
                )}
              </Col>
              {detailSection && !isBelowBreakpointMd && (
                <Col xl={5} lg={6}>
                  <div className={styles['details-container']}>{VimeoComponent()}</div>
                </Col>
              )}
            </Row>
            {ctaSection &&
              ctaSection.map(block => {
                const DynamicComponent = elements[block.componentName]

                // If a button should be disabled.
                if (block.componentName === 'Button') {
                  block.disabled = !block.url && !block.modalFormId
                }

                return (
                  DynamicComponent && (
                    <DynamicComponent
                      key={block.id}
                      {...block}
                      onClick={() =>
                        block.componentName === 'Button' && block.modalFormId
                          ? ctaFormHandler(block.modalFormId, block.title)
                          : gaBtnClick(CTA_CLICK_EVENT, title, block.title)
                      }
                    />
                  )
                )
              })}
          </Container>
        </div>

        {readMore && readMore.html !== '<p></p>' && description && (
          <Container className={'container'}>
            <Row>
              <Col xl={7} lg={6}>
                <button onClick={toggleReadMore} className={styles['read-or-hide']}>
                  {readMoreIsOpen ? 'Read less' : 'Read more'}
                  <div className={cx([styles['caret'], styles[readMoreIsOpen ? '' : 'is-open']])} />
                </button>
                {readMoreIsOpen ? (
                  <div className={styles['read-more-content']}>
                    <Paragraph text={readMore} />
                  </div>
                ) : null}
              </Col>
            </Row>
          </Container>
        )}
        {detailSection && isBelowBreakpointMd && (
          <Container className={'container'}>
            <div className={styles['details-container']}>{VimeoComponent()}</div>
          </Container>
        )}

        <div
          className={styles['hero-container__bg-img']}
          style={{
            backgroundImage: `url(${backgroundImage?.url})`,
            opacity: `${isEdpHero || hasIcimsCareerForm || isSignupPage || isGalleryComponent || logo ? '1' : '.3'}`
          }}
        />
      </div>
      <PortalModal isOpen={showSignUpModal} handleModalClose={onCloseModalHandler} closeOnOutsideClick={false}>
        <Form formId={modalFormId} isChildOfModal={true} formSubmitHandler={formSubmitHandler} />
      </PortalModal>
    </>
  )
}

export default Hero
