import { IArtWorkResponse } from 'types/voting'
import { API_PATHS } from '~constants'
import { CLICK_CONTENT, ANALYTICS_LINK_MODULE_HERO, ANALYTICS_LINK_LOCATION_HEADER, ANALYTICS_LINK_MODULE_CTA } from '~analyticsConstants'
import { gaDLPush } from 'utils/hooks/gaDLPush'
import { LeaderboardProps } from '~types'

export const fetchSubmissionsByGroup = async (group: string, isPast: boolean = false): Promise<IArtWorkResponse[]> => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}${isPast ? API_PATHS.GET_PAST_SUBMISSIONS : API_PATHS.GET_SUBMISSIONS}${group}`
  )
  const submissions = await response.json()

  const mappedSubmissions = submissions.map((submission: any) => {
    return {
      ...submission,
      video: {
        ...submission.video,
        loDefVideo: submission.video?.url ?? ''
      }
    }
  })

  return mappedSubmissions
}
export const fetchLeaderBoardByGroup = async (group: string): Promise<LeaderboardProps[]> => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}${API_PATHS.LEADERBOARD_REPORT}${group}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': `${process.env.NEXT_PUBLIC_SERVER_API_KEY}`
    }
  })
  const { data } = await response.json()

  return data
}

export interface ISubmitXOVote {
  name: string
  email: string
  videoId: string
  videoEducationLevel: number
}

export const submitXOVote = async ({ name, email, videoId, videoEducationLevel }: ISubmitXOVote) => {
  gaDLPush({
    event: CLICK_CONTENT,
    click_tracking: `${ANALYTICS_LINK_MODULE_HERO}:${ANALYTICS_LINK_LOCATION_HEADER}`,
    click_type: ANALYTICS_LINK_LOCATION_HEADER,
    link_location: ANALYTICS_LINK_LOCATION_HEADER,
    link_module: `content:${ANALYTICS_LINK_MODULE_CTA}`,
    link_type: 'internal'
  })
  const rawResponse = await fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}${API_PATHS.CAST_VOTE}`, {
    method: 'PATCH',
    body: JSON.stringify({
      name,
      email,
      submissionId: videoId,
      educationLevel: videoEducationLevel
    }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
  const response = await rawResponse.json()

  if (response.status === '1001') {
    return response
  } else if (response.status === '1009') {
    throw new Error('This email has already voted for this cohort!')
  } else if (response.status === '1003') {
    throw new Error('The voting deadline has passed.')
  } else {
    throw new Error('Something went wrong!')
  }
}
