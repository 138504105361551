import styles from './Leaderboard.module.scss'
import { LeaderboardProps } from '~types'
import Image from 'next/image'
import Slider from 'react-slick'
import { Heading } from '@msgtechnology/sphere'
import { useWindowSize } from 'utils/hooks'
import { useState } from 'react'

const Leaderboard = ({ report }: { report: Array<LeaderboardProps> }) => {
  const { isBreakpoint: isBelowBreakpointMobile } = useWindowSize('md', '<')

  const [slider, setSlider] = useState(null)
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0
  }
  return (
    <div className={styles['leaderboard-container']}>
      <h2>Daily Leaderboard</h2>
      {isBelowBreakpointMobile ? (
        <Slider ref={setSlider} {...sliderSettings}>
          {report.map((item, index) => (
            <div className={styles['thumbnail-container']} key={item._id + index}>
              <div className={`${styles['thumbnail-image-container']} ${styles[`count-${index + 1}-border`]}`}>
                <Image
                  src={item?.posterImage}
                  alt={'Leaderboard Video thumbnail'}
                  sizes="100vw"
                  width={0}
                  height={0}
                  style={{ width: '100%', height: '100%' }}
                  priority
                />
                <div className={`${styles['count']} ${styles[`count-${index + 1}`]}`}>{index + 1}</div>
              </div>
              <div className={styles['details-container']}>
                <Heading level={3} levelDisplay={3} margin="0px 0px 8px">
                  {item?.firstName} {item?.lastName}
                </Heading>
                <div>
                  <p>{item?.schoolName}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <div className={styles['leaderboard-thumbnail-container']}>
          {report.map((item, index) => (
            <div key={`container-${item._id}-${index}`} className={styles['thumbnail-container']}>
              <div className={`${styles['thumbnail-image-container']} ${styles[`count-${index + 1}-border`]}`}>
                <Image
                  src={item?.posterImage}
                  alt={'Leaderboard Video thumbnail'}
                  sizes="100vw"
                  width={0}
                  height={0}
                  style={{ width: '100%', height: '100%' }}
                  priority
                />
                <div className={`${styles['count']} ${styles[`count-${index + 1}`]}`}>{index + 1}</div>
              </div>
              <div className={styles['details-container']}>
                <Heading level={3} levelDisplay={3} margin="0px 0px 8px">
                  {item?.firstName} {item?.lastName}
                </Heading>
                <Heading level={5} levelDisplay={5} margin="0px 0px 8px" className={styles['school-name']}>
                  {item?.schoolName}
                </Heading>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Leaderboard
