import { NextRouter } from 'next/router'
import { ReactElement, RefObject } from 'react'
import { IGroupVotingButton, IVotingButton } from './voting'

export type Nullable<T> = T | null

export interface Twitter {
  handle?: string
  site?: string
  cardType?: string
}

export interface VideoProps {
  autoPlay?: boolean
  backgroundVideo: {
    url: string
  }
  posterImage: {
    url: string
  }
  videoLoop: boolean
  url?: string
  externalVideoRef?: RefObject<HTMLVideoElement>
  handlePlaybackFailed?: (status: boolean) => void
}

export interface SeoProps {
  allowSearchEnginesToShowThisPage: boolean
  canonical?: string
  defaultTitle: string
  facebook?: { appId: string }
  ogImage: {
    url: string
  }
  ogUrl: string
  ogTitle: string
  ogType: string
  ogDescription: string
  metaDescription: string
  seoTitle: string
  shouldSearchEnginesFollowLinks: boolean
  twitter?: Twitter
}

export interface HeroGeneralInfoProps {
  headline: string
  copy: {
    html: string
  }
  infoItems: Array<HeroInfoBarLinkProp>
  backgroundImageXl: ImageProps
  backgroundImageSm: ImageProps
  showBackgroundImage: boolean
}

export interface NavigationHeaderProps {
  navigationMenuItems: NavigationMenuItemsProps
  brandLogo: {
    url: string
    fileName: string
  }
  buttonTitle: string
  buttonUrl: string
  isTargetBlank: boolean
  bannerRef?: any
  endNavItem?: string
  endNavItemUrl?: string
}

export interface NotificationBannerProps {
  copy: { html: string }
  ctaPrimary?: ButtonProps
  showBanner: boolean
}
export interface ImageProps {
  alt?: string
  component?: 'image'
  copyright?: string
  fieldtype?: 'asset' // @TODO: determine other possible values
  fileName: string
  id?: string
  image: {
    url: string
  }
  imageUrlS3?: string
  title?: string
  name?: string
  url: string
  width: number
  height: number
  componentName: string
}

export interface HeroInfoBarLinkProp {
  image: {
    image: ImageProps
  }
  anchorText: string
  url: string
}

export interface InfoBarSectionProps {
  subheadline: string
  description: string
  cta: ButtonProps
  icon: { url: string }
  iconHeight: number
  iconWidth: number
  index: number
  highlightedWords: string
  gradientColor: string
  displayGradient: boolean
  gradientPlacementOnTop: boolean
  subheadlineColor: string
  eyebrow?: string
  img?: ImageProps
  mobileImage?: ImageProps
  defaultImg?: ImageProps
  image?: ImageProps
  iconUrlS3: string
}

export const EducationLevels: string[] = [
  '',
  '',
  '',
  '',
  '4th Grade',
  '5th Grade',
  '6th Grade',
  '7th Grade',
  '8th Grade',
  '9th Grade',
  '10th Grade',
  '11th Grade',
  '12th Grade',
  'Freshman',
  'Sophomore',
  'Junior',
  'Senior',
  'Grad Student'
]

export interface URLProps {
  //anchor: ? @TODO: determine what `anchor` prop type is
  cached_url?: string
  fieldtype: 'multilink' | string // @TODO: determine other possible values
  id?: string
  linktype: 'story' | 'url'
  url: string
}

export type SubNavLinksType = {
  id: string
  isTargetBlank: boolean
  title: string
  url: string
  hasDivider: boolean
  displayFevoWidget?: boolean
  groupTitle?: string
  sectionLinks?: Array<{
    title: string
    url: string
  }>
}

export interface NavigationMenuItemProps {
  footerFormId?: string
  isModalLink?: boolean
  modalContent?: { html: string }
  modalContentTitle?: string
  modalContentCTAText?: string
  modalContentCtaURL?: string
  id: string
  component: 'navigationMenuItem'
  isTargetBlank: boolean
  subNavLinks?: Array<SubNavLinksType>
  title: string
  url: string
  submenuImage: {
    url: string
  }
  submenuImageTitle: string
  submenuImageSubtitle: string
  submenuRedirectUrl: string
  hasDivider: boolean
}

export interface WidgetSectionProps {
  id: string
  ctaBtnText: string
  ctaBtnFormId: string
  disclaimer: string
  subtitle: string
  title: string
}

export interface NavigationColumnProps {
  columnStyleClass: string
  navigationMenuItems: Array<NavigationMenuItemProps>
  title: string
}

export interface BottomNavProps {
  bottomNav: Array<NavigationMenuItemProps>
  legalText: string
  sponsorLogo: any
  taologo: any
}

export interface NavigationColumnsProps extends Array<NavigationColumnProps> {}

export interface NavigationMenuItemsProps extends Array<NavigationMenuItemProps> {}

export interface BenefitProps {
  _uid?: string
  benefitCardIndex: number
  description?: string
  handleModalEvent: (toggleModalFlag: boolean, benefitCardIndex: number, benefitIndex: number) => void
  index: number
  status?: boolean
  sectionTitle: string
  title: string
}

export interface ButtonGroupProps {
  btnGroupTitle: string
  buttons: Array<WidgetSectionProps>
  bottomDivider?: boolean
}

export interface LocationProps {
  id?: string
  ctaText: string
  isLastChild?: boolean
  title: string
  url: string
}

export interface VimeoProps {
  _uid?: string
  url: string
  videoTitle?: string
  videoDescription?: string
  thumbnailUrl?: string
  uploadDate?: string
  isChildOfSmallCard?: boolean
  isChildOfCard?: boolean
  posterImage?: {
    url: string
    fileName: string
  }
  externalUrl: string
  componentName: string
}

export interface PressLinkProps {
  id?: string
  attribution: string
  date: string
  title: string
  url: string
}

export interface CarouselSlideProps {
  _uid?: string
  defaultImg: ImageProps
  description: string
  handleImageClick: Function
  imageDescription: string
  imageTitle: string
  img: ImageProps
  mobileImage: ImageProps
  showModal: boolean
  title: string
}

export interface CardMediaProps {
  _uid?: string
  columnWidth: number
  isChildOfGrid?: boolean
  isTargetBlank?: boolean
  mediaColumn: Array<ImageProps> | Array<VimeoProps>
  subTitle?: string
  title: string
  url?: string
}

export type ArtistIntroCardType = {
  backgroundImage: {
    url: string
  }
  logo: {
    url: string
  }
  title: string
  backgroundImageUrlS3: string
  logoUrlS3: string
}

export type BenefitItemType = {
  id: string
  component: 'Benefit'
  description: string
  title: string
}

export type BenefitsCardType = {
  benefitCardIndex: number
  icon: {
    fileName: string
    url: string
  }
  title: string
  items: Array<BenefitItemType>
  handleModalEvent: (status: boolean, benefitCardIndex: number, index: number) => void
}

export interface BenefitsProps {
  icon: ImageProps
  items: Array<BenefitsCardType>
  title: string
}

export interface DoesNotExistProps {
  router: NextRouter
  backgroundImageS3Url: string
  buttonGroup: Array<ButtonProps>
  businessUnit: string
}

export interface ButtonProps {
  asLink?: boolean
  anchorLink?: string
  dataID?: string
  bgColor: string | 'primary' | 'dark' | 'light'
  className?: string
  color?: string | 'primary' | 'dark' | 'light'
  displayLinkInline?: boolean
  disabled?: boolean
  form?: string
  isEnabled?: boolean
  isFullWidth?: boolean
  isTargetBlank?: boolean
  justify?: 'left' | 'center' | 'right'
  linkId?: string
  linkLocation?: string
  linkModule?: string
  linkPosition?: number
  linkType?: string
  clickType?: string
  clickSource?: string
  clickTracking?: string
  tagManagerEvent?: string
  cmsIdentifier?: string
  id?: string
  modalFormId?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement> | null
  size?: string | 'xs' | 'sm' | 'md' | 'lg'
  title: string
  fill?: string | 'hollow' | 'solid' | 'text'
  url?: string
  wantCfc?: boolean
  modalBlocks?: any
  buttonPlacementDescription?: string
  buyType?: string
  displayFevoWidget?: boolean
  wantUtm?: boolean
}

export interface HeadingProps {
  children: ReactElement | string
  color?: string | 'accent' | 'dark' | 'light' | 'lightest'
  level: string | number | 1 | 2 | 3 | 4 | 5 | 6
  levelDisplay?: string | 1 | 2 | 3 | 4 | 5 | 6 | 'display' | 'eyebrow'
  justify?: 'left' | 'center'
  title?: string
  id?: string
}

export interface EventProps {
  date_time: number
  formatted_start_time: string
  host_url: string
  id: string
  is_sold_out: boolean
  start_time: string
  name: string
  price: PriceProps
  spanMultipleDays?: boolean
  end_date?: string
}

interface ArtistProps {
  id: string
  artistImage: ImageProps
  artistName: string
}

interface PriceProps {
  min: number
  max: number
}

export interface EventDateProps {
  end_date: string
  spanMultipleDays: boolean
  artists: Array<ArtistProps>
  date: number
  events: Array<EventProps>
  is_best_value: boolean
  is_holiday: boolean
  is_on_sale: boolean
  is_sold_out: boolean
  is_sponsored_day: boolean
  price: PriceProps
  sponsors: Array<String>
  venue_id: string
}

export interface EventDatesAPIResponseProps {
  results: Array<EventDateProps>
}

export interface NavProps {
  noOfMonths: number
  startDate: string
}

export interface MonthProps {
  label: string
  value: string
  shortLabel: string
}

export interface CalendarNavProps {
  calendarData: Map<string, object> | object
  calendarFilterItems: Array<MonthProps>
  selectedMonth: MonthProps
  allEvents: Array<EventDateProps>
}

export interface CalendarProps {
  authorizationToken: string
  calendarId: string
  confirmationModalButtonCtaText: string
  eventEngineApiUrl: string
  eventsLimit: number
  eventsPerPage: number
  heading: string
  isChildOfCard?: boolean
  loadCalendarDataFromS3: boolean
  numberOfMonths: number
  overrideApiSoldOutStatus: boolean
  showCalendar: boolean
  showMonthFilter: boolean
  showMonthSeparator: boolean
  showPagination: boolean
  soldOutConfirmationModalText: { html: string }
  staticCalendarDataS3Url: string
  title?: string
  businessUnit?: string
}

export interface EventDatesProps {
  eventDates: Array<EventDateProps>
}

export interface PillProps {
  eyebrow?: string
  headline?: string
  description?: string
  ctaText: string
  ctaURL: string
}

export interface ParagraphProps {
  fontSize?: string | 'lg' | 'md' | 'sm' | 'xs'
  justify?: 'center' | 'left'
  showReadMore?: boolean
  text: { html: string }
}

export interface ShowsCardProps {
  backgroundImage: { url: string; redirectUrl?: string }
  buttonGroup?: Array<ButtonProps>
  headline?: string
  eyebrow?: string
  subheadline?: string
  description?: { html: string }
}

export interface FormProps {
  formId: string
  formSubmitHandler?: () => void
  inputBgColor?: 'black' | 'transparent' | 'white'
  isChildOfModal?: boolean
  submitButtonBgColor?: 'white'
  submitButtonText?: string
  showSuccessMessageInModal: boolean
  onCloseModalHandler: Function
  formSuccessLogo: ImageProps
  modalSuccessMessage: {
    html: string
  }
  modalSuccessTitle: string
}

export interface ModalProps {
  modalBackgroundUrl?: string
  bgColor?: string | 'gray' | 'transparent'
  children?: any
  closeOnOutsideClick?: boolean
  contentWidth?: 'narrow' | 'wide'
  handleModalClose: () => void
  isOpen: boolean
  panel?: boolean
  type?: string
}

export interface ContentModuleSectionProps {
  blocks: Array<ParagraphProps | VimeoProps | ButtonProps | HeadingProps | ImageProps | FormProps | ModalProps>
  showDividerAtBottom: boolean
  showDividerAtTop: boolean
  dividerFullWidth?: boolean
  contentModuleDivId?: string
  constantsMap: any
}

export interface ContentModuleProps {
  constantsMap: any
  moduleSections: Array<ContentModuleSectionProps>
}

export interface ConstantMapProps {
  title: string
  value: object
}

export interface HeroFullScreenSlideProps {
  img: ImageProps
  pill: PillProps
}

export interface HeroFullScreenProps {
  backgroundImage: {
    url: string
  }
  carouselSlides: Array<HeroFullScreenSlideProps>
  subTitle?: string
  textLogo: {
    fileName: string
    url: string
  }
  title: string
}

export interface SocialMediaItemProps {
  icon: ImageProps
  platformName: string
  url: string
}

export interface PartnershipItemProps {
  icon: ImageProps
  platformName: string
  url: string
  newtab: boolean
}

export interface SocialMediaItemPropsStandalone {
  icon: ImageProps
  platformName: string
  url: string
}

export interface SocialMediaRowProps {
  align: string | 'center' | 'left'
  socialMediaRowHeadline: string
  socialMediaRow: Array<SocialMediaItemProps>
}

export interface PartnershipRowProps {
  align: string | 'center' | 'left'
  partnershipRowHeadline: string
  partnershipRow: Array<PartnershipItemProps>
}

export interface FooterProps {
  generalInformation: {
    title: string
    addressLine1: string
    addressLine2: string
    phoneNumber: string
  }
  legalText: string
  logoPrimary: {
    url: string
  }
  logoSecondary: {
    url: string
  }
  navMenu1: NavigationMenuItemsProps
  navMenu2: NavigationMenuItemsProps
  navMenu3: NavigationMenuItemsProps
  navMenuLegal: NavigationMenuItemsProps
  socialMediaRow: Array<SocialMediaItemProps>
  partnershipRow: Array<PartnershipItemProps>
  socialMediaRowHeadline: string
}

export interface FooterPropsStandalone {
  navMenuLegal: NavigationMenuItemsProps
  socialMediaIcons: Array<SocialMediaItemPropsStandalone>
}

export interface LeaderboardProps {
  educationLevel: number
  artWorkTitle: string
  schoolName: string
  firstName: string
  lastName: string
  posterImage: string
  voteCount: number
  _id: string
}

export interface GalleryProps {
  galleryPlaylist?: Array<any>
  tabLabels?: Array<string>
  resultsHeading: string
  activeTab?: string
  id: string
  filterHeading: string
  filterValue: string
  sortByHeading: string
  sortByOptions: string[]
  isLeaderBoard: Boolean
  heroTitle: string
  heroDescription: {
    html: string
  }
  heroBgImage?: ImageProps
  votingModalReminder?: string
  votingModalDisclaimer?: string
  votingDeadline?: string
  groupVotingButton: IGroupVotingButton
  isWinnersView?: boolean
}

export interface CohortVotingButtonProps {
  title: string
  votingButtons: IVotingButton[]
}

export interface IVideo {
  url: string
  posterImage: string
  title: any
  uploadDate: string
}

export interface IconButtonsProps {
  id: string
  enable: boolean
  icon: {
    url: string
  }
  cta: string
  url: string
  openLinkInNewTab: boolean
  imageUrlS3: string
}

export interface IconButtonsCardProps {
  enable: boolean
  eyebrow: string
  headline: string
  backgroundImage: {
    url: string
  }
  backgroundVideo?: {
    url: string
  }
  iconButtons: Array<IconButtonsProps>
  backgroundImageUrlS3: string
}

export interface BackgroundVideoProps {
  video: { url: string }[]
  posterImage: { url: string; fileName: string }[]
  posterImageUrlS3: string
}

export interface BackgroundMedia extends ImageProps, BackgroundVideoProps {
  id: string
  componentName: string
  imageUrlS3: string
}

export interface GroupTicketingCardProps {
  id: string
  media: BackgroundMedia
  headline?: string
  eyebrow?: string
  eyebrowColor?: string
  description?: { html: string }
  backgroundGradient: string
  backgroundColor?: {
    hex: string
  }
  buttonGroup?: Array<ButtonProps>
  hasMediaInRightColumn?: boolean
  groupTicketingCardId: string
  heading?: string
  headlineIsCenterAligned?: boolean
  eyebrowIsCenterAligned?: boolean
  descriptionIsCenterAligned?: boolean
  buttonGroupIsCenterAligned?: boolean
}

export interface ISignUpProps {
  componentName: string
  id: string
  heading: string
  headingIsCenterAligned?: boolean
  body: {
    html: string
  }
  bodyIsCenterAligned?: boolean
  disclaimer: {
    html: string
  }
  disclaimerIsCenterAligned?: boolean
  submitButton: ButtonProps
  redirectAfterFormSubmit?: string
  buttonGroup?: Array<ButtonProps>
  buttonsAreCenterAligned?: boolean
  confirmationModalText: {
    html: string
  }
  backgroundMedia: BackgroundMedia
  mobileBackgroundMedia: BackgroundMedia
  formId: string
  checkboxIsDisabled?: boolean
}

export interface ISignUpFormElements extends HTMLFormControlsCollection {
  name: HTMLInputElement
  email: HTMLInputElement
  age: HTMLInputElement
}

export interface ISignUpSubmission extends HTMLFormElement {
  elements: ISignUpFormElements
}
